@import 'base/functions';
@import 'base/variables';
@import 'base/mixins';
@import 'base/sprite';
@import 'base/fonts';
@import 'base/grid';
@import 'base/base';
@import 'base/icon';
@import 'base/typography';
@import 'base/button';
@import 'base/loader';

@import 'blocks/forms';


@import 'sections/header';
@import 'sections/footer';

@import 'base/print';

.bredcrumbs {
	margin: 16px 0 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;

	&__item {
		margin-right: 36px;
		margin-bottom: 0;
		position: relative;
		margin-bottom: 16px;
		&:after {
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -18px;
			content: '';
			width: 4px;
			height: 4px;
			margin-left: 8px;
			border-radius: 50%;
			background-color: #000;

		}
		&:last-of-type{
			&:after {
				display: none;
			}
		}
	}

	&__link {
		display: block;
		text-decoration: none;
		margin-bottom: 0;
		font-weight: bold;
		font-size: 10px;
		line-height: 11px;
		text-transform: uppercase;
		@media (max-width: 660px){
			word-break: break-word;
		}
	}
	a{
		color:  #9C9C9C;
	}
	p{
		color: #FF8300;
	}
}

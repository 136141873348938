.loader {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.lds-dual-ring {
    display: block;
    width: 64px;
    height: 64px;

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 46px;
        height: 46px;
        margin: 1px;
        border: 5px solid $white-color;
        transform: translate(-50%, -50%);
        animation: lds-dual-ring 1.2s linear infinite;
        border-radius: 50%;
        border-color: $typography-primary-color transparent $typography-primary-color transparent;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

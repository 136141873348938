.footer {
  margin: 16px 0 0;
  padding: 16px 0;
  border-top: 1px solid $border-color;

  &__wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    @media(max-width: 768px) {
      flex-direction: column;
    }
  }

  &-item {
    font-size: 13px;
    @media(max-width: 768px) {
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &-contacts {
    @media(max-width: 768px) {
      margin: 8px 0;
    }
    &__item {
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      &--link {
        @include transition();
        text-decoration: none;
      }
    }
  }
}
.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-family--icon !important;
    speak: none;
    font-style: normal;
    font-weight: $font-weight;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.icon__arrow-down-small {
        &::before {
            content: '\1f32b';
        }
    }

    &.icon__arrow-left {
        &::before {
            content: '\e901';
        }
    }

    &.icon__arrow-right {
        &::before {
            content: '\e902';
        }
    }

    &.icon__arrow-up {
        &:before {
            content: '\e903';
        }
    }

    &.icon__avatar {
        &::before {
            content: '\e904';
        }
    }

    &.icon__call-stroke-light {
        &::before {
            content: '\e905';
        }
    }

    &.icon__mail-fill {
        &::before {
            content: '\e906';
        }
    }

    &.icon__facebook-stroke {
        &::before {
            content: '\e907';
        }
    }

    &.icon__facebook-fill {
        &::before {
            content: '\e908';
        }
    }

    &.icon__arrow-right-small {
        &::before {
            content: '\e909';
        }
    }

    &.icon__options {
        &::before {
            content: '\e90a';
        }
    }

    &.icon__close {
        &::before {
            content: '\e90b';
        }
    }

    &.icon__like {
        &::before {
            content: '\e90c';
        }
    }

    &.icon__arrow-left-small {
        &:before {
            content: '\e90d';
        }
    }

    &.icon__arrow-down-small {
        &::before {
            content: '\e90e';
        }
    }

    &.icon__arrow-top-small {
        &::before {
            content: '\e90f';
        }
    }

    &.icon__instagram-stroke-light {
        &::before {
            content: '\e910';
        }
    }

    &.icon__instagram-stroke-bold {
        &::before {
            content: '\e911';
        }
    }

    &.icon__log-in {
        &::before {
            content: '\e912';
        }
    }

    &.icon__mail-stroke {
        &::before {
            content: '\e913';
        }
    }

    &.icon__map-pin-stroke {
        &::before {
            content: '\e914';
        }
    }

    &.icon__odnoklassniki-stroke-light {
        &::before {
            content: '\e915';
        }
    }

    &.icon__odnoklassniki-stroke-bold {
        &::before {
            content: '\e916';
        }
    }

    &.icon__burger {
        &::before {
            content: '\e917';
        }
    }

    &.icon__call-stroke-bold {
        &::before {
            content: '\e918';
        }
    }

    &.icon__map-pin-fill {
        &::before {
            content: '\e919';
        }
    }

    &.icon__search-bold {
        &::before {
            content: '\e91a';
        }
    }

    &.icon__search-light {
        &::before {
            content: '\e91b';
        }
    }

    &.icon__basket-stroke {
        &::before {
            content: '\e91c';
        }
    }

    &.icon__basket-fill {
        &::before {
            content: '\e91d';
        }
    }

    &.icon__telegram-stroke {
        &::before {
            content: '\e91e';
        }
    }

    &.icon__telegram-fill {
        &::before {
            content: '\e91f';
        }
    }

    &.icon__user {
        &::before {
            content: '\e920';
        }
    }

    &.icon__viber-stroke {
        &::before {
            content: '\e921';
        }
    }

    &.icon__viber-fill {
        &::before {
            content: '\e922';
        }
    }

    &.icon__vk-stroke {
        &::before {
            content: '\e923';
        }
    }

    &.icon__vk-fill {
        &::before {
            content: '\e924';
        }
    }

    &.icon__whatsapp-stroke {
        &::before {
            content: '\e925';
        }
    }

    &.icon__whatsapp-fill {
        &::before {
            content: '\e926';
        }
    }

    &.icon__youtube-stroke {
        &::before {
            content: '\e927';
        }
    }

    &.icon__youtube-fill {
        &::before {
            content: '\e928';
        }
    }

    &.icon__download {
        background-image: url('../images/svg/icon-download.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}



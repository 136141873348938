*, *::after, *::before {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: $font-size--root;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  min-height: 100vh;

}

body {
  margin: 0;
  padding: 0;
  background-color: $white-color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $typography-primary-color;
  min-height: 100vh;
}

input[type='text'], input[type='email'], input[type='search'], input[type='password'], select, button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
}

[hidden] {
  display: none !important;
}

.clearfix {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > .content {
    flex: 1 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  min-width: map_get($wrapper, content-size--min);
  max-width: map_get($wrapper, content-size);
  padding-left: map_get($wrapper, padding-size);
  padding-right: map_get($wrapper, padding-size);
  margin: 0 auto;
}

.overflow {
  overflow: hidden;
}

.fullpage {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &--filter {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .5;
      background-image: linear-gradient(0deg, rgba(67, 52, 59, 0.5), rgba(67, 52, 59, 0.5));
    }

    .wrapper {
      position: relative;
      z-index: 2;
    }
  }
}

.icon {
  display: inline-block;

  &--arrow-right {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    text-align: center;
    background-image: url(../img/svg/arrow_right.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.pagination-list {
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);

  &__item {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $white-color;
    margin: 16px 0;
    position: relative;
    @include transition();

    &:before, &:after {
      @include transition();
    }

    &.active {
      background-color: $function-primary-color;

      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        transform: translate(-30%, -30%);
        border: 1px solid $function-primary-color;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    &:hover {
      background-color: $function-primary-color--hover;

      &:after {
        content: attr(data-title);
        position: absolute;
        right: calc(100% + 16px);
        top: 50%;
        transform: translateY(-50%);
        color: $white-color;
        font-family: $font-family;
        font-size: rem(18);
        line-height: 1.22;
        text-shadow: 2px 4px 10px rgba(32, 32, 32, 0.3);
        text-align: right;

      }
    }
  }
}

.modal-form {
  max-width: 560px;
  padding: 24px 32px;
  border-radius: 5px;
  background-color: $theme-light-color;
  margin: 0 auto;

  .field {
    margin-bottom: 24px;
  }

  .btn {
    display: block;
    margin: 48px auto 0;
    padding: 16px 24px;
    background-color: $function-primary-color;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    max-width: 346px;
    cursor: pointer;

    &:hover {
      background-color: lighten($function-primary-color, 5%);
    }
  }
}

@charset "UTF-8";
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/montserrat-v15-latin_cyrillic-500.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/montserrat-v15-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v15-latin_cyrillic-500.woff2") format("woff2"), url("../fonts/montserrat-v15-latin_cyrillic-500.woff") format("woff"), url("../fonts/montserrat-v15-latin_cyrillic-500.ttf") format("truetype"), url("../fonts/montserrat-v15-latin_cyrillic-500.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-700 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/montserrat-v15-latin_cyrillic-700.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/montserrat-v15-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v15-latin_cyrillic-700.woff2") format("woff2"), url("../fonts/montserrat-v15-latin_cyrillic-700.woff") format("woff"), url("../fonts/montserrat-v15-latin_cyrillic-700.ttf") format("truetype"), url("../fonts/montserrat-v15-latin_cyrillic-700.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
  max-width: 1136px;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px; }
  .wrapper--fluid {
    max-width: none; }

*, *::after, *::before {
  box-sizing: border-box; }

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 8px;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
  min-height: 100vh; }

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  min-height: 100vh; }

input[type='text'], input[type='email'], input[type='search'], input[type='password'], select, button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none; }

[hidden] {
  display: none !important; }

.clearfix::after {
  content: '';
  display: block;
  clear: both; }

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .container > .content {
    flex: 1 0 auto;
    position: relative;
    display: flex;
    flex-direction: column; }

.wrapper {
  position: relative;
  width: 100%;
  min-width: 280px;
  max-width: 1136px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto; }

.overflow {
  overflow: hidden; }

.fullpage {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .fullpage--filter {
    position: relative; }
    .fullpage--filter:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .5;
      background-image: linear-gradient(0deg, rgba(67, 52, 59, 0.5), rgba(67, 52, 59, 0.5)); }
    .fullpage--filter .wrapper {
      position: relative;
      z-index: 2; }

.icon {
  display: inline-block; }
  .icon--arrow-right {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    text-align: center;
    background-image: url(../img/svg/arrow_right.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }

.pagination-list {
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%); }
  .pagination-list__item {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 16px 0;
    position: relative;
    transition: all 0.2s ease-in-out 0s; }
    .pagination-list__item:before, .pagination-list__item:after {
      transition: all 0.2s ease-in-out 0s; }
    .pagination-list__item.active {
      background-color: #FF8300; }
      .pagination-list__item.active:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        transform: translate(-30%, -30%);
        border: 1px solid #FF8300;
        background: rgba(255, 255, 255, 0.2); }
    .pagination-list__item:hover {
      background-color: #ff8f1a; }
      .pagination-list__item:hover:after {
        content: attr(data-title);
        position: absolute;
        right: calc(100% + 16px);
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
        font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 2.25rem;
        line-height: 1.22;
        text-shadow: 2px 4px 10px rgba(32, 32, 32, 0.3);
        text-align: right; }

.modal-form {
  max-width: 560px;
  padding: 24px 32px;
  border-radius: 5px;
  background-color: #FAFAFA;
  margin: 0 auto; }
  .modal-form .field {
    margin-bottom: 24px; }
  .modal-form .btn {
    display: block;
    margin: 48px auto 0;
    padding: 16px 24px;
    background-color: #FF8300;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    max-width: 346px;
    cursor: pointer; }
    .modal-form .btn:hover {
      background-color: #ff8f1a; }

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .icon.icon__arrow-down-small::before {
    content: '\1f32b'; }
  .icon.icon__arrow-left::before {
    content: '\e901'; }
  .icon.icon__arrow-right::before {
    content: '\e902'; }
  .icon.icon__arrow-up:before {
    content: '\e903'; }
  .icon.icon__avatar::before {
    content: '\e904'; }
  .icon.icon__call-stroke-light::before {
    content: '\e905'; }
  .icon.icon__mail-fill::before {
    content: '\e906'; }
  .icon.icon__facebook-stroke::before {
    content: '\e907'; }
  .icon.icon__facebook-fill::before {
    content: '\e908'; }
  .icon.icon__arrow-right-small::before {
    content: '\e909'; }
  .icon.icon__options::before {
    content: '\e90a'; }
  .icon.icon__close::before {
    content: '\e90b'; }
  .icon.icon__like::before {
    content: '\e90c'; }
  .icon.icon__arrow-left-small:before {
    content: '\e90d'; }
  .icon.icon__arrow-down-small::before {
    content: '\e90e'; }
  .icon.icon__arrow-top-small::before {
    content: '\e90f'; }
  .icon.icon__instagram-stroke-light::before {
    content: '\e910'; }
  .icon.icon__instagram-stroke-bold::before {
    content: '\e911'; }
  .icon.icon__log-in::before {
    content: '\e912'; }
  .icon.icon__mail-stroke::before {
    content: '\e913'; }
  .icon.icon__map-pin-stroke::before {
    content: '\e914'; }
  .icon.icon__odnoklassniki-stroke-light::before {
    content: '\e915'; }
  .icon.icon__odnoklassniki-stroke-bold::before {
    content: '\e916'; }
  .icon.icon__burger::before {
    content: '\e917'; }
  .icon.icon__call-stroke-bold::before {
    content: '\e918'; }
  .icon.icon__map-pin-fill::before {
    content: '\e919'; }
  .icon.icon__search-bold::before {
    content: '\e91a'; }
  .icon.icon__search-light::before {
    content: '\e91b'; }
  .icon.icon__basket-stroke::before {
    content: '\e91c'; }
  .icon.icon__basket-fill::before {
    content: '\e91d'; }
  .icon.icon__telegram-stroke::before {
    content: '\e91e'; }
  .icon.icon__telegram-fill::before {
    content: '\e91f'; }
  .icon.icon__user::before {
    content: '\e920'; }
  .icon.icon__viber-stroke::before {
    content: '\e921'; }
  .icon.icon__viber-fill::before {
    content: '\e922'; }
  .icon.icon__vk-stroke::before {
    content: '\e923'; }
  .icon.icon__vk-fill::before {
    content: '\e924'; }
  .icon.icon__whatsapp-stroke::before {
    content: '\e925'; }
  .icon.icon__whatsapp-fill::before {
    content: '\e926'; }
  .icon.icon__youtube-stroke::before {
    content: '\e927'; }
  .icon.icon__youtube-fill::before {
    content: '\e928'; }
  .icon.icon__download {
    background-image: url("../images/svg/icon-download.svg");
    background-repeat: no-repeat;
    background-position: center; }

b, strong {
  font-weight: 700; }

p {
  margin-top: 0; }

a {
  color: #FF8300; }
  a:hover, a:focus {
    color: #e67600; }
  a:active {
    color: #db7100; }

ol, ul, dl {
  list-style: none;
  margin: 0 0 2rem;
  padding: 0 0 0 1rem; }
  ol ol, ol ul, ol dl, ul ol, ul ul, ul dl, dl ol, dl ul, dl dl {
    margin: 1rem 0 0; }
  ol dd, ol dt, ol li, ul dd, ul dt, ul li, dl dd, dl dt, dl li {
    margin-bottom: 1rem; }

ol {
  list-style: decimal inside; }

ul {
  list-style: disc inside; }

h1, h2, h3, h4, h5, h6 {
  width: 100%;
  font-weight: 700;
  margin-bottom: 24px;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 0; }

h1 {
  font-size: 5rem;
  line-height: 46px;
  letter-spacing: 0; }

h2 {
  font-size: 5rem;
  line-height: 1.375;
  letter-spacing: 0.01em;
  font-weight: 500; }

h3 {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: 0; }

h4 {
  font-size: 3rem;
  line-height: 28px;
  letter-spacing: 0; }

h5 {
  font-size: 2.5rem;
  line-height: 1.5;
  letter-spacing: 0; }

h6 {
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0; }

blockquote {
  border-left: 0.25rem solid #e9e9e9;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 2rem;
  font-style: normal; }

q, cite {
  font-style: italic; }

code {
  display: block;
  width: 100%;
  margin: 0 0 2rem;
  padding: 1rem 2rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  white-space: break-word; }

pre {
  display: block;
  width: 100%;
  overflow: auto;
  border-left: 0.25rem solid #e9e9e9;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  pre > code {
    white-space: pre; }

hr {
  margin: 2rem 0;
  border: 0;
  border-bottom: 0.125rem solid #e9e9e9; }

picture {
  display: block; }
  picture img {
    display: block;
    max-width: 100%;
    margin: auto; }

table {
  width: 100%;
  margin-bottom: 2rem;
  vertical-align: middle;
  border-collapse: collapse; }
  table th, table td {
    padding: 1rem;
    border-bottom: 1px solid #e9e9e9; }
  table tbody {
    vertical-align: inherit; }
  table thead th {
    text-align: left;
    vertical-align: inherit;
    border-bottom-color: #e9e9e9; }

.btn {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  padding: 18px;
  border-radius: 15px;
  text-decoration: none; }
  .btn--primary {
    color: #ffffff;
    background: #ffffff;
    border: none; }
    .btn--primary:hover, .btn--primary:active, .btn--primary:focus {
      outline: none;
      background: #FAFAFA;
      color: #ffffff; }
  .btn--link {
    color: #FF8300;
    text-transform: uppercase;
    padding: 0; }
    .btn--link:hover, .btn--link:active, .btn--link:focus {
      outline: none;
      color: #ff8f1a; }
    .btn--link.btn--icon {
      display: inline-flex;
      align-items: center; }
      .btn--link.btn--icon .icon {
        margin-left: 40px;
        position: relative;
        transition: all 0.2s ease-in-out 0s;
        right: 0; }
      .btn--link.btn--icon:hover .icon {
        right: 10px; }

.loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4); }

.lds-dual-ring {
  display: block;
  width: 64px;
  height: 64px; }
  .lds-dual-ring::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 46px;
    height: 46px;
    margin: 1px;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
    animation: lds-dual-ring 1.2s linear infinite;
    border-radius: 50%;
    border-color: #000 transparent #000 transparent; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.field {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  cursor: pointer; }
  .field:first-child {
    margin-top: 0; }
  .field__input {
    outline: none;
    display: block;
    border: 1px solid rgba(135, 135, 135, 0.4);
    padding: 8px 12px;
    background-color: #ffffff;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 16px;
    color: #000;
    margin-bottom: 8px; }
    .field__input:disabled {
      background: rgba(206, 206, 206, 0.2); }
    .field__input:focus {
      border-color: #ff8f1a; }
    .field__input::placeholder {
      color: #9C9C9C;
      font-weight: 400;
      font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.75rem;
      line-height: 16px; }
    .field__input:required + .field__title::after {
      content: '*';
      color: #eb5757;
      margin-left: 4px; }
  .field__title {
    font-size: 1.75rem;
    line-height: 1.57;
    color: #000;
    margin-bottom: 4px; }
  .field__toggle-password {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 26px;
    right: 1px;
    width: 45px;
    height: 45px;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer; }
    .field__toggle-password:active .icon path, .field__toggle-password.active .icon path {
      fill: #FF8300; }
    .field__toggle-password .icon {
      display: flex;
      width: 24px;
      height: 24px; }
      .field__toggle-password .icon svg {
        width: 100%;
        height: 100%; }
      .field__toggle-password .icon path {
        transition: all 0.2s ease-in-out 0s; }
  .field ul {
    margin: 4px 0 0;
    padding: 0;
    list-style: none;
    font-size: 1.75rem;
    line-height: 2rem;
    color: #eb5757;
    text-align: left; }
    .field ul + .field__input {
      border-color: #eb5757; }
    .field ul li {
      margin: 0;
      font-size: 1.75rem;
      line-height: 2rem; }
      .field ul li:after, .field ul li:before {
        display: none; }

/*Стили чекбоксов и радиокнопок*/
.field-check {
  display: block;
  position: relative;
  cursor: pointer; }
  .field-check:first-child {
    margin-top: 0; }
  .field-check__input {
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    margin: 0;
    opacity: 0; }
    .field-check__input[type=checkbox] + .field-check__name::before {
      transition: all 0.2s ease-in-out 0s;
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #9C9C9C;
      border-radius: 4px; }
    .field-check__input[type=checkbox] + .field-check__name::after {
      content: '';
      position: absolute;
      top: 7px;
      left: 4px;
      width: 10px;
      height: 10px;
      background-image: url(../images/svg/checkbox-icon.svg);
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
    .field-check__input[type=checkbox] + .field-check__name:hover::before {
      border-color: #FF8300; }
    .field-check__input[type=checkbox]:checked + .field-check__name::before {
      border-color: #FF8300;
      background: #FF8300; }
    .field-check__input[type=radio] + .field-check__name::before {
      transition: all 0.2s ease-in-out 0s;
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #9C9C9C;
      border-radius: 50%; }
    .field-check__input[type=radio] + .field-check__name::after {
      transition: all 0.2s ease-in-out 0s;
      content: '';
      position: absolute;
      top: 7px;
      left: 6px;
      width: 8px;
      height: 8px;
      background: #FF8300;
      border-radius: 50%;
      opacity: 0; }
    .field-check__input[type=radio] + .field-check__name:hover::before {
      border-color: #FF8300; }
    .field-check__input[type=radio]:checked + .field-check__name::before {
      border-color: #FF8300; }
    .field-check__input[type=radio]:checked + .field-check__name::after {
      opacity: 1; }
  .field-check__name {
    display: block;
    position: relative;
    font-size: 2rem;
    line-height: 1.5;
    color: #000;
    padding-left: 30px; }

/* input file */
.field-file {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  cursor: pointer; }
  .field-file__input {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 59px;
    pointer-events: none; }
  .field-file__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    font-size: 1.75rem;
    line-height: 1.85;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #e9e9e9; }
    @media (max-width: 768px) {
      .field-file__content {
        padding: 5px; } }
  .field-file__browse {
    display: block;
    color: #ffffff;
    padding: 0 16px;
    background: #FF8300;
    margin-bottom: 8px;
    border-radius: 4px; }
  .field-file__text {
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.header {
  width: 100%;
  padding: 40px 0 8px;
  position: relative;
  flex: 0 0 auto; }
  .header:after {
    content: '';
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
    position: absolute;
    background-image: linear-gradient(90deg, #f5931c, #ffff05); }
  @media (max-width: 820px) {
    .header-contacts {
      margin-bottom: 10px; } }
  .header-contacts__item {
    transition: all 0.2s ease-in-out 0s;
    font-size: 18px;
    text-decoration: none; }
    @media (max-width: 1024px) {
      .header-contacts__item {
        font-size: 16px; } }
  .header .wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 820px) {
      .header .wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center; } }
  .header__logo {
    height: 51px;
    display: block;
    margin-bottom: 16px; }
    @media (max-width: 820px) {
      .header__logo {
        height: 30px;
        margin-bottom: 16px; } }
    .header__logo img {
      display: block;
      height: 100%; }
  .header__name {
    display: block;
    height: 29px; }
    @media (max-width: 820px) {
      .header__name {
        height: 15px; } }
    .header__name img {
      display: block;
      height: 100%; }

.footer {
  margin: 16px 0 0;
  padding: 16px 0;
  border-top: 1px solid #e9e9e9; }
  .footer__wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center; }
    @media (max-width: 768px) {
      .footer__wrapper {
        flex-direction: column; } }
  .footer-item {
    font-size: 13px; }
    @media (max-width: 768px) {
      .footer-item {
        margin-top: 8px; }
        .footer-item:first-child {
          margin-top: 0; } }
  @media (max-width: 768px) {
    .footer-contacts {
      margin: 8px 0; } }
  .footer-contacts__item {
    margin-top: 8px; }
    .footer-contacts__item:first-child {
      margin-top: 0; }
    .footer-contacts__item--link {
      transition: all 0.2s ease-in-out 0s;
      text-decoration: none; }

@media print {
  *, *:before, *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a, a:visited {
    text-decoration: underline !important; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  a[href^='#']:after, a[href^='javascript:']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre, blockquote {
    border: 1px solid #999 !important;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  table {
    border-collapse: collapse !important; }
    table td, table th {
      background-color: #fff !important; } }

.bredcrumbs {
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap; }
  .bredcrumbs__item {
    margin-right: 36px;
    margin-bottom: 0;
    position: relative;
    margin-bottom: 16px; }
    .bredcrumbs__item:after {
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: -18px;
      content: '';
      width: 4px;
      height: 4px;
      margin-left: 8px;
      border-radius: 50%;
      background-color: #000; }
    .bredcrumbs__item:last-of-type:after {
      display: none; }
  .bredcrumbs__link {
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    text-transform: uppercase; }
    @media (max-width: 660px) {
      .bredcrumbs__link {
        word-break: break-word; } }
  .bredcrumbs a {
    color: #9C9C9C; }
  .bredcrumbs p {
    color: #FF8300; }

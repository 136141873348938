.header {
    width: 100%;
    padding: 40px 0 8px;
	position: relative;
	flex: 0 0 auto;

	&:after {
		content: '';
		bottom: 0;
		height: 2px;
		left: 0;
		right: 0;
		position: absolute;
		background-image: linear-gradient(90deg, rgba(245,147,28,1), rgba(255,255,5,1));
	}

	&-contacts {
		@media(max-width: 820px) {
			margin-bottom: 10px;
		}
		&__item {
			@include transition();
			font-size: 18px;
			text-decoration: none;
			@media(max-width: 1024px) {
				font-size: 16px;
			}
		}
	}

	.wrapper {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 820px){
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

	}

	&__logo{
		height: 51px;
		display: block;
		margin-bottom: 16px;
		@media (max-width: 820px){
			height: 30px;
			margin-bottom: 16px;
		}

		img {
			display: block;
			height: 100%;
		}
	}

	&__name{
		display: block;
		height: 29px;
		@media (max-width: 820px){
			height: 15px;
		}

		img {
			display: block;
			height: 100%;
		}
	}

}
